import { createRouter, createWebHistory } from 'vue-router'
import Predict from '../components/Predict.vue'
import HelloWorld from '@/components/HelloWorld.vue'
import VideoSplit from '@/components/VideoSplit.vue' 
import PictureMaster from '@/components/PictureMaster.vue'
//import IndexPage from '@/components/IndexPage.vue'
import IntelligentEvaluation from '@/components/EvaluationPage.vue'
import HomeWork from '@/components/HomeWork.vue'
import AITesting from '@/components/AITesting.vue'
import ExclusivePlan from '@/components/ExclusivePlan.vue'
import ParentsMeeting from '@/components/ParentsMeeting.vue'
import PsyHealth from '@/components/PsyHealth.vue'
import WrittingCorrect from '@/components/WrittingCorrect.vue'
import FitnessEva from '@/components/FitnessEva.vue'
import KnowledgeTree from '@/components/KnowledgeTree.vue'
import TestingReport from '@/components/TestingReport.vue'
import PersonalStage from '@/components/PersonalStage.vue'
const routes = [
  // ...其他路由规则
  {
    path: '/hello',
    name: 'HelloWorld',
    component: HelloWorld
  },
//  {
//    path: '/',
//    name: 'Index',
//    component: IndexPage
//  },
  {
    path:'/predict',
    name:'PredictPage',
    component: Predict
  },
  {
    path: '/vs',
    name: 'VideoSplit',
    component: VideoSplit
  },
  {
    // 课件插图
    path: '/picmaster',
    name: 'PictureMaster',
    component: PictureMaster
  },
  {
    // 学生评价
    path: '/eva',
    name: 'EvaluationPage',
    component: IntelligentEvaluation
  },
  {
    // 作业助手
    path: '/homework',
    name: 'HomeWork',
    component: HomeWork
  },
  {
    // 作文批改
    path: '/writting-correct',
    name: 'WrittingCorrect',
    component: WrittingCorrect
  },
  {
    // 智能考试
    path: '/ai-testing',
    name: 'AITesting',
    component: AITesting,
  },
  {
    // 考试成绩报告
    path: '/testing-report',
    name: 'TestingReport',
    component: TestingReport
  }, 
  {
    // 体质评价
    path: '/fitness-eva',
    name: 'FitnessEva',
    component: FitnessEva
  },
  {
    // 心理健康
    path: '/psy',
    name: 'psy',
    component: PsyHealth
  },
  {
    // 家长会
    path: '/parents-meeting',
    name: 'ParentsMeeting',
    component: ParentsMeeting
  },
  {
    // 专属成长方案
    path: '/exclusive-plan',
    name: 'ExclusivePlan',
    component: ExclusivePlan
  },
  {
    // 知识树
    path: '/knowledge-tree',
    name: 'KnowledgeTree',
    component: KnowledgeTree
  },
   {
     // 伪首页
     path: '/',
     name: 'PersonalStage',
     component: PersonalStage 
   }
]
 
const router = createRouter({
  history: createWebHistory(),
  routes
})
 
export default router

<template>
    <div class="container">
      <div class="setup">
        <span class="title">注意事项</span>
        <span class="memo">- 请在准备好之后点击开始考试</span>
        <span class="memo">- 考试时间为60分钟，考试结束后请按退出考试按钮退出考试</span>
        <span class="memo">- 听力部分每道题会播放2遍</span>
        <span class="memo">- 考试结束后会展示考试成绩</span>
        <span class="memo">- 请不要使用无痕模式的浏览器</span>
        <span class="memo">- 请确保浏览器可以使用您设备的扬声器</span>
      </div>
      
      <div class="ready">
        <el-button v-if="!testStarting" class="ready_rest_time_startbutton" type="primary" @click="startTesting">开始考试</el-button>
        <el-button v-else class="ready_rest_time_startbutton" type="primary" disabled>开始考试</el-button>
      </div>

        <!-- 阅读 -->
        <div v-loading="loading" v-if="testStarting" class="test_paper">
          <span class="test_paper_part_name">Reading</span>
          <!-- 1~6 题 -->
            <span class="test_paper_type_name">Questions 1~6 </span>
            <span class="test_paper_type_instruction">
              For each question, choose the correct answer.  
            </span>
            <el-divider></el-divider>
            <div class="reading_part_1_group" v-for="(item,index) in RP1" :key="item.id">
              <div class="reading_part_1_group_text">
                <span  class="reading_part_1_group_text_id">Question - {{index+1 }}</span>
                <img  class="reading_part_1_group_text_img"  :src="item.img" alt="reading_picture">
              </div>
              <div class="reading_part_1_group_stem_options">
                <div class="reading_part_1_group_stem">Question - {{index+1}} <br/> {{item.stem}}</div>
                
                <div class="reading_part_1_group_options" 
                    v-for="o in item.options" 
                    :key="o.id"
                     @click="set_RP1('RW',3*(index-1)+o.id-1,item.id,o.id,index+1)" >  <!--set_RP1(3*(item.id-1)+o.id-1)  -->
                     <span :class="reading_part1_options[3*(index-1)+o.id-1]">{{o.option+". "+o.answer}}</span>
                     <!-- <span :class="reading_part1_options[3*(item.id-1)+o.id-1]">{{o.option+". "+o.answer}}</span> -->
                  </div>
              </div>
              
            </div>

          <!-- 7~13 题 -->
          <span class="test_paper_type_name">Questions 7~13 </span>
            <span class="test_paper_type_instruction">
              For each question, choose the correct answer.  
            </span>
            <el-divider></el-divider>

            <div class="reading_part_2_group_title">{{RP2.title}}</div>
           
            <div class="reading_part_2_group">
              <div class="reading_part_2_group_text">
                  <div class="reading_part_2_group_text_item" v-for="r2 in RP2.texts" :key="r2.id">
                    <div class="reading_part_2_group_text_item_name">{{r2.name}}</div>
                    <div class="reading_part_2_group_text_item_text">{{r2.text}}</div>
                  </div>
              </div>
              
              <div class="reading_part_2_group_stem_options">
                <div class="reading_part_2_group_stem_options_row">
                  <div class="reading_part_2_group_stem_options_row_stem"><span style="display:none">placeholder</span></div>
                  <div class="reading_part_2_group_stem_options_row_option reading_part_2_group_names_row_tag" 
                  v-for="name in RP2.texts" :key="name.name">{{name.name}}</div>
                </div>
                
              
                  <div class="reading_part_2_group_stem_options_row"
                    v-for="(item,index) in RP2.questions" :key="item.id"
                  >
                 
                    <div class="reading_part_2_group_stem_options_row_stem">{{index+1+". "+item.stem}}</div>
                    <div class="reading_part_2_group_stem_options_row_option"  
                      @click="set_RP2('RW',3*(index)+option.id-1,item.id,option.id,index+7)"
                      v-for="option in item.options" :key="option.id">
                      <span :class="reading_part2_options[3*(index)+option.id-1]"> {{option.option}}</span></div> 
                  </div>

              </div>
              
            </div>

          <!-- 14~18 题 -->
          <span class="test_paper_type_name">Questions 14~18 </span>
          <span class="test_paper_type_instruction">
            For each question, choose the correct answer.  
          </span>
          <el-divider></el-divider>

          <div class="reading_part_3_group">
            <div class="reading_part_3_group_text_group">
              <div class="reading_part_3_group_text_group_title">{{RP3.title }}
              </div>
              <div class="reading_part_3_group_text_group_text">{{RP3.text }}
              </div>
            </div>
           
            <div class="reading_part_3_group_stem_options_group">
              <div class="reading_part_3_group_stem_options" v-for="(item,index) in RP3.questions" :key="item.id">
                <div class="reading_part_3_group_stem">{{index+1+". "+item.stem}}</div>
                <div class="reading_part_3_group_options" 
                  v-for="o in item.options" 
                  :key="o.id"
                  @click="set_RP3('RW',3*(index)+o.id-1,item.id,o.id,index+14)"
                  >
                  <span :class="reading_part3_options[3*(index)+o.id-1]">{{o.option+". "+o.answer}}</span>
                </div>
            </div>
            </div>
          </div> 

          <!-- 19~24 题 -->
          <span class="test_paper_type_name">Questions 19~24 </span>
          <span class="test_paper_type_instruction">
            For each question, choose the correct answer.  
          </span>
          <el-divider></el-divider>

          <div class="reading_part_4_group">
            <div class="reading_part_4_group_text_group">
              <div class="reading_part_4_group_text_group_title">{{RP4.title }}
              </div>
              <div class="reading_part_4_group_text_group_text">{{RP4.text }}
              </div>
            </div>
            
            <div class="reading_part_4_group_stem_options_group">
              <div class="reading_part_4_group_stem_options" v-for="(item,index) in RP4.questions" :key="item.id">
                <div class="reading_part_4_group_stem">{{index+1+". "+item.stem}}</div>
                <div class="reading_part_4_group_options" 
                  v-for="o in item.options" 
                  :key="o.id"
                  @click="set_RP4('RW',3*(index)+o.id-1,item.id,o.id,index+19)"
                  >
                  <span :class="reading_part4_options[3*(index)+o.id-1]">{{o.option+". "+o.answer}}</span>
                </div>
            </div>
            </div>
          </div> 

        </div>

        <!-- 写作 -->
        <div  v-loading="loading"  v-if="testStarting" class="test_paper">
          <span class="test_paper_part_name">Writing</span>
          <!-- 25~30 题 -->
            <span class="test_paper_type_name">Questions 25~30 </span>
            <span class="test_paper_type_instruction">
              For each question, choose the correct answer.  Write one word for each gap.
            </span>
            <span class="test_paper_example">
              Example: 
              <span style="margin-left:10px;font-weight:600;text-align:center; width:30px;height:20px; border:solid 1px #000;font-size:10pt">
                0</span>
              <span  style="font-weight:600;text-align:center;width:30px;height:20px; font-style:italic; border:solid 1px #000;font-size:10pt">
                {{WP1.example}}</span>
            </span>
            <el-divider></el-divider>
            <div class="writing_part_5_group">
              <div class="writing_part_5_group_text">{{WP1.text }}
              </div>
              <div class="writing_part_5_group_blanks"
                    v-for="o in 6" 
                    :key="o">
                    {{ "("+(o+24)+")." }}
                    <el-input style="width:240px" 
                    :placeholder="'Fill in ('+(o+24)+') anwser'"
                    v-model="WP1_Filling[o-1]"></el-input>
              </div>
            </div>

          <!-- 31 题 -->
          <el-divider></el-divider>
          <span class="test_paper_type_name">Questions 31 </span>
          <div class="writing_part_6_group">
            <div class="writing_part_6_group_text">
              <span class="writing_part_6_group_stem">{{WP2.stem }}</span>
              <span class="writing_part_6_group_require">{{WP2.require }}</span>
              <span class="writing_part_6_group_notic">{{WP2.notic }}</span>
            </div>
            <div class="writing_part_6_group_blanks">
                 
                  <el-input style="width:480px;" rows=10 
                  type="textarea"
                  placeholder="Write here"
                  v-model="WP2_Filling"></el-input>
            </div>
          </div>  


        <!-- 32 题 -->
        <el-divider></el-divider>
        <span class="test_paper_type_name">Questions 32 </span>
        <div class="writing_part_7_group">
          <div class="writing_part_7_group_text">
            <span class="writing_part_7_group_stem">{{WP3.stem }}</span>
            <span class="writing_part_7_group_require">{{WP3.require }}</span>
           
          </div>
          <div class="writing_part_7_group_imgs">
            <img class="writing_part_7_group_image"  
            v-for="i in WP3.imgs" :key="i.index" :src="i.url">
          </div>
          <span class="writing_part_7_group_notic">{{WP3.notic }}</span>
          <div class="writing_part_7_group_blanks">
               
                <el-input style="width:480px;" rows=10 
                type="textarea"
                placeholder="Write here"
                v-model="WP3_Filling"></el-input>
          </div>
        </div>  
      </div>

        <!-- 听力 1~6-->
        <div  v-loading="loading"  v-if="testStarting" class="test_paper">
          <span class="test_paper_part_name">Listening</span>
          <span class="test_paper_type_name">Questions 1~6 </span>
          <span class="test_paper_type_instruction">
            For each question, choose the correct answer.  
          </span>
          <div :class="listening_part1_play_btn_status" @click="play_audio(LPS.audioURL,LPS.id)">
            <el-icon class="listening_part_select_img_stem_playbtn"><Headset />
            </el-icon><span class="listening_part_select_img_stem_playbtn">Click here to play, each section could only be played once.</span>  
          </div>
          <el-divider></el-divider>
              
          <div class="listening_part_select_img_group" v-for="(item,index) in LPS.questions" :key="item.id">
            <div class="listening_part_select_img_stem">
              {{index+1+". "+item.stem}}
            </div>
            
            <div class="listening_part_select_img_options">
              <div class="listening_part_select_img_quiz" 
              @click="set_LPSI('L',3*(index)+option.id-1,item.id,option.id,index+1)"  
              v-for="option in item.options" :key="option.id"> 
              
                <img :class="listening_part_select_img_quiz_pic[3*(index)+option.id-1]" :src="option.img"/>
                <span :class="listening_part_select_img_quiz_option[3*(index)+option.id-1]">{{option.option}}</span>
              
              </div> 
            </div>
            
          </div>
          
           <!-- 7~12 题 -->
           <span class="test_paper_type_name">Questions 7~12 </span>
           <span class="test_paper_type_instruction">
             For each question, write the correct answer in the gap. Write one word or a number or a date or a time.  
           </span>

           <div :class="listening_part2_play_btn_status" @click="play_audio(LP2.audioURL,LP2.id)">
            <el-icon class="listening_part_select_img_stem_playbtn"><Headset />
            </el-icon><span class="listening_part_select_img_stem_playbtn">Click here to play, each section could only be played once.</span>  
          </div>
           <el-divider></el-divider>
          
           <div class="listening_part_select_img_stem">
           {{LP2.tips}}
          </div>

           <div class="listening_part_2_group">
            <div class="listening_part_2_group_text_group">
              <div class="listening_part_2_group_text_group_title">{{LP2.title }}
              </div>
              <div v-for="(q,index) in LP2.quizs" :key="q.id" class="listening_part_2_group_text_group_quizs">
                <div class="listening_part_2_group_text_group_quizs_stem">{{index+1+". "+q.stem }}</div>
                <div class="listening_part_2_group_text_group_quizs_blank">
                  {{q.blank }}
                  <el-input 
                  v-model="LP2_Blanks[index]" 
                  v-if="q.id!=1" :placeholder="index+1" 
                  style="width:240px;margin-left:10px"></el-input>
                </div>
              </div>
            </div> 
          </div>

         <!-- 13~17 题 -->
         <span class="test_paper_type_name">Questions 13~17 </span>
         <span class="test_paper_type_instruction">
           For each question,choose the correct answer.  
         </span>

         <div :class="listening_part3_play_btn_status" @click="play_audio(LP3.audioURL,LP3.id)">
          <el-icon class="listening_part_select_img_stem_playbtn"><Headset />
          </el-icon><span class="listening_part_select_img_stem_playbtn">Click here to play, each section could only be played once.</span>  
        </div>
         <el-divider></el-divider>
        
          <div class="listening_part_select_img_stem">
            {{LP3.tips}}
          </div>

          <div class="reading_part_1_group" v-for="(item,index) in LP3.quizs" :key="item.id">

            <div class="listening_part_3_group_stem_options">
                <div class="listening_part_3_group_stem">{{index+1+". "+item.stem}}</div>
                <div class="listening_part_3_group_options" 
                  v-for="o in item.options" 
                  :key="o.id"
                   @click="set_LP3('L',3*(index)+o.id-1,item.id,o.id,index+13)" >
                  <span :class="listening_part3_options[3*(index)+o.id-1]">{{o.option+". "+o.answer}}</span>
                </div>
            </div>
            
          </div>
          

        <!-- 18~22 题 -->
         <span class="test_paper_type_name">Questions 18~22 </span>
         <span class="test_paper_type_instruction">
           For each question,choose the correct answer.  
         </span>
       
         <div :class="listening_part4_play_btn_status" @click="play_audio(LP4.audioURL,LP4.id)">
          <el-icon class="listening_part_select_img_stem_playbtn"><Headset />
          </el-icon><span class="listening_part_select_img_stem_playbtn">Click here to play, each section could only be played once.</span>  
        </div>
         <el-divider></el-divider>
        
          
          <div class="reading_part_1_group" v-for="(item,index) in LP4.quizs" :key="item.id">

            <div class="listening_part_4_group_stem_options">
                <div class="listening_part_4_group_stem">
                 {{index+1+". "+item.stem}}
                </div>
                <div class="listening_part_4_group_options" 
                  v-for="o in item.options" 
                  :key="o.id"
                   @click="set_LP4('L',3*(index)+o.id-1,item.id,o.id,index+18)" >
                  <span :class="listening_part4_options[3*(index)+o.id-1]">{{o.option+". "+o.answer}}</span>
                </div>
            </div>
            
          </div>

        <!-- 23~28 题 -->


          <span class="test_paper_type_name">Questions 23~28 </span>
          <span class="test_paper_type_instruction">
            For each question, choose the correct answer.  
          </span>
          <div :class="listening_part5_play_btn_status" @click="play_audio(LP5.audioURL,LP5.id)">
            <el-icon class="listening_part_select_img_stem_playbtn"><Headset />
            </el-icon><span class="listening_part_select_img_stem_playbtn">Click here to play, each section could only be played once.</span>  
          </div> 
          <div class="listening_part_5_group_stem">
           
            {{LP5.tips}}
            <span class="test_paper_example">
              Example: 
              <span  style="font-weight:500;text-align:center;font-size:11pt;margin-right:20px">
                  {{LP5.example_text}}
              </span>
              <span  style="font-weight:600;text-align:center;width:30px;height:20px; font-style:italic; border:solid 1px #000;font-size:10pt">
                  {{LP5.example_answer}}
              </span>
            </span>
            <el-divider></el-divider>
          </div>
         
          <b>You now have six mintues to write your answers on the answer sheet.</b>
        
          <div style="display:flex; flex-direction:row;margin-top:40px">
            <span style="font-weight:550;flex:1">{{LP5.texts_title}}</span>
            <span style="font-weight:550;flex:1">{{LP5.options_title}}</span>
          </div>

          <div class="listening_part5_group">
          <div class="listening_part5_group_blanks">
           <span class="listening_part5_group_blanks_text" 
           v-for="(l5b,index) in LP5.texts" 
           :key="l5b.id"
          >{{ "("+(index+1)+"). "+l5b.text }}
          <el-input style="width:240px;margin-left:5px" 
          :placeholder="'Fill in ('+(index+1)+') anwser'"
          v-model="LP5_Filling[index]"></el-input>
        </span>
         
                
          </div>
        
          <div class="listening_part5_group_options"
                >
               <div v-for="o in LP5.options" 
               :key="o.id"
               class="listening_part5_group_options_item">
                
                  {{ o.option+"."+o.answer }}
                 
                 
              </div>
          </div>
        </div>
      </div>   


      <!-- 口语 -->
      <div  v-loading="loading"  v-if="testStarting" class="test_paper">
        <span class="test_paper_part_name">Speaking</span>
       
          <span class="test_paper_type_instruction">
            This is a simulated speaking test. Calling to AI interlocutor when you are ready.
          </span>
          <el-divider></el-divider>

          <div class="listening_part5_group">
            <transition name="flip" mode="out-in" @after-enter="speakingTest_onEnter">
              <div class="speaking_dail" v-if="!calling">
                <div class="speaking_dail_avatar"></div>
                <span class="speaking_dail_name">AI Interlocutor</span>
                <el-button v-if="canCalling" type="primary"  icon="Phone"  @click="start_speaking_test">Calling</el-button>
              </div>
              <div class="speaking_dailing flipping" v-else>
                <div class="speaking_dailing_avatar_group">
                  <div class="speaking_dailing_interlocutor" :class="{jelly:interlocutorJelly}" v-show="interlocutorJelly">
                    <div class="speaking_dailing_interlocutor_avatar" :class="{breathing_light:interlocutorBreathLight}"></div>
                    <span class="speaking_dailing_interlocutor_name">AI Interlocutor</span>
                  </div>
                  <div class="speaking_dailing_you" :class="{jelly:youJelly}" v-show="youJelly">
                    <div class="speaking_dailing_you_avatar" :class="{breathing_light:youBreathLight}">U</div>
                    <span class="speaking_dailing_you_name">You</span>
                  </div>
                  <div class="speaking_dailing_partner" :class="{jelly:AIPartnerJelly}" v-show="AIPartnerJelly">
                    <div class="speaking_dailing_partner_avatar" :class="{breathing_light:partnerBreathLight}">P</div>
                    <span class="speaking_dailing_partner_name">AI Partner</span>
                  </div>
                 </div>
              </div>
            </transition>
            </div>
            <div v-if="!canCalling" style="margin-bottom:10px">Please press <b>Talking</b> button and start to speaking when the breathing light for letter <b>"U"</b> flashes.</div>
           
            <div class="speaking_dailing_img" v-if="showSpeakingImg">
              <img alt="口语考试题图片" :src="speakingImgUrl"  />
            </div>
            <div style="margin-bottom:50px" v-if="speakingStart"><el-button type="primary" @click="startSpeakingTest">Talking</el-button></div>
          </div>

      <div class="footer">
        <div class="ready_rest_time">剩余时间:<span class="ready_rest_time_countdown">{{restTime}}</span></div>
        <!-- <div class="rest_question"><span class="rest_question_num" >答题数量：</span><span class="rest_question_num_yet">{{answerYet}}</span>/<span class="rest_question_num_total">59</span></div> -->
        <div>
          <el-button v-if="testStarting" class="ready_rest_time_startbutton" type="primary" @click="endTesting">交卷</el-button>
         <el-button v-else class="ready_rest_time_startbutton" type="primary" disabled>交卷</el-button>    
        </div> 
      </div>
    </div>   
</template>
   
  <script>
  //百度统计
  var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?9a1d5ebcc313c38be1aa304887b31e1f";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

    import axios from "axios"
    import {ElMessage} from "element-plus"
    export default {
        name: 'AITesting',
        components:{
           
        },
        metaInfo:{
           title:"KET模拟考试",
           meta:[
             {name:"description",content:"KET模拟考试"}
           ]
        },
        data(){
            return{
               uid:"7e279da5-e739-524f-bb42-24b2b2d23590",
               restTime:"--:--",
               testStarting:false,// 调试之后改回来
               answers:[],// 答案

               loading:false,

               RP1:[],// 阅读1~6题
               RP2:{},// 阅读7~13题
               RP3:[],// 阅读14~18题
               RP4:[],// 阅读19~24题
               reading_part1_options:[], // 阅读第一部分选项样式
               reading_part2_options:[], // 阅读第二部分选项样式
               reading_part3_options:[], // 阅读第三部分选项样式
               reading_part4_options:[], // 阅读第四部分选项样式

               WP1:{},// 写作题第一部分
               WP1_Filling:[],// 写作题第一部分填空题答案
               
               WP2:{},// 写作题第二部分
               WP2_Filling:null,// 写作题第二部分填空题答案

               WP3:{},// 写作题第三部分
               WP3_Filling:null,// 写作题第三部分填空题答案

               isListeningPlaying:false,// 听力播放状态
               LPS:[],// 听力1选图题
               listening_part_select_img_quiz_pic:[], // 听力选图题图片样式
               listening_part_select_img_quiz_option:[], // 听力选图题选项样式
               
               listening_part1_play_btn_status:"listening_play_enable", // 听力第1部分播放按钮状态
               listening_part2_play_btn_status:"listening_play_enable", // 听力第2部分播放按钮状态
               listening_part3_play_btn_status:"listening_play_enable", // 听力第3部分播放按钮状态
               listening_part4_play_btn_status:"listening_play_enable", // 听力第4部分播放按钮状态
               listening_part5_play_btn_status:"listening_play_enable", // 听力第5部分播放按钮状态
               
               LP2:{},// 听力2部分
               LP2_Blanks:[],// 听力2部分填空题答案

               LP3:{},// 听力3部分
               listening_part3_options:[], // 听力第三部分选项样式

               LP4:{},// 听力4部分
               listening_part4_options:[], // 听力第四部分选项样式

               LP5:{},// 听力5部分
               LP5_Filling:[], // 听力第五部分选填空

               SP:['g-1','g-2','g-3','p1-1-1','p1-1-2','p2-1-1','p2-1-2','p2-1-3','p2-1-4','p2-1-5'],//口语题

               calling:false, //与口语面试官通话中
               interlocutorJelly:false, // 面试官头像果冻动画
               youJelly:false, // 考生头像果冻动画
               AIPartnerJelly:false, // ai学伴头像果冻动画

               showSpeakingImg:false, // 显示口语部分图片
               interlocutorBreathLight:false, // 面试官呼吸灯动画
               youBreathLight:false, // 考生呼吸灯动画
               partnerBreathLight:false, // ai学伴呼吸灯动画

              //  inter:[],
              //  you:[],
              //  partner:[]
              speakingStart:false,
              canCalling:true,
              speakingImgUrl:null,
            }
        },
        computed:{
          answerYet(){
            return this.answers.length
          }
        },
        mounted(){
            this.SP_Conversation=[{
              "id":1,
              "officer":"go on",
              "a":"I prefer to take public transportation, which is low-carbon and environmentally friendly, and the cost is very low. The most important thing is that the bus is very punctual. What method do you prefer.",
              "you":"Don't ask me if I don't understand."
            }]
        },
        methods: {
          startCountDown(){
            this.restTime="59:59"
            let strtime=this.restTime.split(":")
            let time=[parseInt(strtime[0]),parseInt(strtime[1])]
            setInterval(()=>{
              if(time[1]==0&&time[0]==0){
                clearInterval()
                this.restTime="--:--"
              }else{
                if(time[1]!=0){
                  time[1]-=1
                }else{
                  time[1]=59
                  time[0]-=1
                }
                this.restTime= time[0].toString().padStart(2,'0')+":"+time[1].toString().padStart(2,'0')
              }
             
            },1000)
          },

          play_audio(url,id){
            // 按id 检查cookie中记录的播放次数
            console.log("document.cookie：",document.cookie)
            if(this.checkCookie(id)<2&&!this.isListeningPlaying){
              console.log("允许播放：",url,"btn_status",this.listening_part1_play_btn_status)
              this.isListeningPlaying=true
              // 播放
              let audio = new Audio(url)
              audio.onended = function(){
                this.listening_part1_play_btn_status="listening_play_enable"
                this.isListeningPlaying=false
              }
              audio.play().catch(e=>{
                console.log("播放失败：",e)
                this.isListeningPlaying=false
              })
            }else{
              this.listening_part1_play_btn_status="listening_play_disable"
              console.log("禁止播放：",url,"btn_status",this.listening_part1_play_btn_status)
            }
          },

          checkCookie(key){
             // 获取当前的时间
             const now=new Date();
             const extactTime=(this.restTime.split(':')[0]*5+this.restTime[1]*1)*1000
             const expiresIn =new Date(now.getTime()+extactTime); // 5分钟后过期
            console.log("key:",key)
            console.log("expiresIn:",expiresIn) 
            // 获取 cookies
            const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
              const [k, v] = cookie.split('=');
              acc[k] = decodeURIComponent(v);
              return acc;
            }, {});
            let newValue=parseInt(cookies[key])
            if (key in cookies) {
              // 如果 cookie 存在，值加 1
              newValue += 1;}else{
                newValue=1;
              }
              document.cookie = `${key}=${newValue}; expires=${expiresIn.toUTCString()}; path=/`;
           
            console.log("newValue:",newValue)
            return newValue;
          },

          async startTesting(){
            this.loading=true
            this.testStarting=true
            // 开始倒计时
            this.startCountDown()
            // 删除全部cookie 
            const cookies = document.cookie.split("; ");  
            
            // 遍历每一个 cookie 并删除  
            for (let cookie of cookies) {  
              const eqPos = cookie.indexOf("=");  
              const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;  
            
              // 设置 cookie 的过期时间为一个过去的日期，以删除该 cookie  
              document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";  
            }  
            // 开始组卷
            const headers={
                    'Content-type': 'application/json'
                }
                await axios.post('/ai-testing/api/ali-ket-exam/',{headers})
                //await axios.post('http://localhost:8080/ali-ket-exam/',{headers})
                .then((res)=>{
                      // 阅读第一部分组卷
                      for(let i=0;i<=5;i++){
                        this.RP1.push(res.data[i])
                      }
                      this.RP1.forEach((element)=>{
                          element.options.forEach(()=>{
                              this.reading_part1_options.push("reading_part_1_options") 
                          })
                        })

                      // 阅读第二部分组卷
                      this.RP2=res.data[6]
                      this.RP2.questions.forEach((element)=>{
                          element.options.forEach(()=>{
                              this.reading_part2_options.push("reading_part_2_group_stem_options_row_option_tag") 
                          })
                        })

                      // 阅读第三部分组卷
                      this.RP3=res.data[7]
                      this.RP3.questions.forEach((element)=>{
                          element.options.forEach(()=>{
                              this.reading_part3_options.push("reading_part_3_group_options") 
                          })
                        })

                      // 阅读第四部分组卷
                      this.RP4=res.data[8]
                      //console.log("RP4 data:",this.RP4)
                      this.RP4.questions.forEach((element)=>{
                          element.options.forEach(()=>{
                              this.reading_part4_options.push("reading_part_4_group_options") 
                          })
                        })

                        // 写作第一部分组卷
                        this.WP1=res.data[9]
                        // 写作第二部分组卷
                        this.WP2=res.data[10]
                        // 写作第三部分组卷
                        this.WP3=res.data[11]

                        // 听力第一部分组卷
                        this.LPS=res.data[12]
                        //console.log("LPS data:",res.data[12])
                        this.LPS.questions.forEach((element)=>{
                            element.options.forEach(()=>{
                                // console.log(option)
                                this.listening_part_select_img_quiz_pic.push("listening_part_select_img_quiz_pic"), // 听力选图题图片样式
                                this.listening_part_select_img_quiz_option.push("listening_part_select_img_quiz_option") 
                                // console.log(this.listening_part_select_img_quiz_pic) 
                                // console.log(this.listening_part_select_img_quiz_option) 
                            })
                          })
                          
                        // 听力第二部分组卷
                        this.LP2=res.data[13]
                          
                        // 听力第三部分组卷
                        this.LP3=res.data[14]

                        // 听力第四部分组卷
                        this.LP4=res.data[15]
                        // 听力第五部分组卷
                        this.LP5=res.data[16]
                       
                        this.loading=false
                  }).catch(err=>{
                    this.loading=false
                    ElMessage({
                      message:("组卷失败")})
                    console.log("组卷失败",err)
                })
                //  console.log("reading part 2 options:",this.reading_part2_options)
          },
          // 写答案纸
          write_answer_sheet(_type,k,v,serial){
             // 创建一个新对象
             const newAnswer = { [k]: v+"@"+serial,"type":_type };
            
            console.log("newAnswer:",newAnswer)
            // 查找是否存在相同的键
            const existingIndex = this.answers.findIndex(answer => Object.prototype.hasOwnProperty.call(answer,k));

            if (existingIndex !== -1) {
                // 如果存在，更新值
                this.answers[existingIndex][k] = v+"@"+serial;
            } else {
                // 如果不存在，添加新对象
                this.answers.push(newAnswer);
            }
            console.log("answers:",this.answers)
          },

          set_RP1(type,key,qid,answer,serial){
            // 阅读第一部分题目样式选择
            console.log("set_RP1:",key) 
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests) 
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j) 
              this.reading_part1_options[j]="reading_part_1_options"
            }
            // 选中新值
           this.reading_part1_options[key]=("reading_part_1_options_selected") 
           console.log("check_answer:",qid+":"+answer)
           this.write_answer_sheet(type,qid,answer,serial)
          },
          set_RP2(type,key,qid,answer,serial){
            // 阅读第2部分题目样式选择
            console.log("set_RP2:",key) 
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests) 
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j) 
              this.reading_part2_options[j]="reading_part_2_group_stem_options_row_option_tag"
            }
            // 选中新值
           this.reading_part2_options[key]=("reading_part_2_group_stem_options_row_option_tag_selected")
           console.log("check_answer:",qid+":"+answer)
           this.write_answer_sheet(type,qid,answer,serial) 
          },
          set_RP3(type,key,qid,answer,serial){
            // 阅读第3部分题目样式选择
            console.log("set_RP3:",key) 
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests) 
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j) 
              this.reading_part3_options[j]="reading_part_3_group_options"
            }
            // 选中新值
           this.reading_part3_options[key]=("reading_part_3_group_options_selected")
           console.log("check_answer:",qid+":"+answer)
           this.write_answer_sheet(type,qid,answer,serial)  
          },
          set_RP4(type,key,qid,answer,serial){
            // 阅读第4部分题目样式选择
            console.log("set_RP4_style:",key) 
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests) 
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j) 
              this.reading_part4_options[j]="reading_part_4_group_options"
            }
            // 选中新值
           this.reading_part4_options[key]=("reading_part_4_group_options_selected")
           console.log("check_answer:",qid+":"+answer)
           this.write_answer_sheet(type,qid,answer,serial)   
          },  

          set_LPSI(type,key,qid,answer,serial){
            // 听力选图题的样式选择
            // 听力选图题图片样式

            console.log("set_LPSI_style:",key) 
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests) 
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j) 
              this.listening_part_select_img_quiz_pic[j]="listening_part_select_img_quiz_pic"
              this.listening_part_select_img_quiz_option[j]="listening_part_select_img_quiz_option"
            }
            // 选中新值
            this.listening_part_select_img_quiz_pic[key]=("listening_part_select_img_quiz_pic_selected")
            this.listening_part_select_img_quiz_option[key]=("listening_part_select_img_quiz_option_selected")
            this.write_answer_sheet(type,qid,answer,serial)   
          },
          set_LP3(type,key,qid,answer,serial){
            // 阅读第3部分题目样式选择
            console.log("set_LP3_style:",key) 
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests) 
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j) 
              this.listening_part3_options[j]="listening_part_3_group_options"
            }
            // 选中新值
           this.listening_part3_options[key]=("listening_part_3_group_options_selected")
           this.write_answer_sheet(type,qid,answer,serial)    
          },
          set_LP4(type,key,qid,answer,serial){
            // 阅读第4部分题目样式选择
            console.log("set LP4 style:",key)
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests)
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j)
              this.listening_part4_options[j]=("listening_part_4_group_options")
            }
            // 选中新值
           this.listening_part4_options[key]=("listening_part_4_group_options_selected")
           this.write_answer_sheet(type,qid,answer,serial)    
          },
          // 开始口语考试
          start_speaking_test(){
            let audio = new Audio("https://sam-test.oss-cn-beijing.aliyuncs.com/assets/dailing.mp3")
            
            audio.play() 
       
            audio.onplay=()=>{
              this.calling=true
              setTimeout(()=>{
                          this.interlocutorJelly=true
                          this.youJelly=true
                        },4000)  
            }

            audio.onended=()=>{
              this.speakingStart=true
              this.canCalling=false
            }
            
          },
          // 口语考试动画播放完成
          speakingTest_onEnter(){
            this.interlocutorJelly=false,
            this.youJelly=false
            this.AIPartnerJelly=false
          },
          startSpeakingTest(){
            this.speakingStart=false

            // this.interlocutorBreathLight=true
            // this.youBreathLight=false
            // this.AIPartnerBreathLight=false

            // 考官提第一个问题
            this.speaking_tts(this.SP.shift(),true)
          },

          // 问题内容转语音
          async speaking_tts(qid,ttsEnable=false){
            this.interlocutorBreathLight=true
            this.youBreathLight=false
            this.AIPartnerBreathLight=false
            const headers={
                    'Content-type': 'application/json'
                }
              const data={
                  "qid":qid,
                  "tts":ttsEnable
                }
                console.log("speaking_tts:",data)
                await axios.post('/ai-testing/api/speaking_tts/',data,{headers})
                .then((res)=>{
                  console.log("speaking_tts_res:",res.data)
                  if(res.data.img){
                    this.speakingImgUrl=res.data.img
                    this.showSpeakingImg=true
                  }
                      console.log("正在播放：",res.data.text)
                      // 播放结束后，开始录制学生的回答
                      console.log("开始录制学生的回答：",res.data.qid)
                      this.startRecording(res.data.qid)
                    })
                .catch((err)=>{
                  console.log("err:",err)
                })
            },

            // 开始录音
            async startRecording(qid) {
              this.interlocutorBreathLight=false
              this.youBreathLight=true
              this.AIPartnerBreathLight=false

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.mediaRecorder = new MediaRecorder(stream);
            this.audioChunks = [];

            this.mediaRecorder.ondataavailable = (event) => {
                  this.audioChunks.push(event.data);
                };

                this.mediaRecorder.start(qid);

                // 监听音频流的停顿
                this.mediaRecorder.onstart = (qid) => {
                  console.log(qid,"speaking start...")
                  this.resetSilenceTimer(qid);
                };

                this.mediaRecorder.onstop = async (qid) => {
                  const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
                  this.transcript = await this.sendAudioToServer(audioBlob,qid);
                  this.audioChunks = []; // 清空音频块
                  this.interlocutorBreathLight=false
                  this.yourBreathLight=false
                  this.partnerBreathLight=false
                };
              },

              resetSilenceTimer(qid) {
                clearTimeout(this.silenceTimer);
                this.silenceTimer = setTimeout(() => {
                  this.mediaRecorder.stop(qid);
                }, 3000); // 3秒无声音自动停止
              },

              async sendAudioToServer(audioBlob,qid) {
                const formData = new FormData();
                formData.append('audio', audioBlob, 'recording.wav');
                formData.append('qid', qid);
                console.log("speaking stop!");
                  const res = await axios.post('/ai-testing/api/student_audio/', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  });
                  console.log(res.data);
                  if (this.SP.length != 0&&res.data.code==200){
                    this.speaking_tts(this.SP.shift(),true)
                  }
                 
                  //return res.data.transcript;
              },

          // 考试结束，提交答案
          async endTesting(){
            try{
                  this.loading=true
                  this.speakingStart=false
                  this.canCalling=true
                  // 把填空题答案补充到答题纸上
                  this.WP1_Filling.forEach((item,index)=>{
                    this.write_answer_sheet("RW",this.WP1.id+"-0"+(index+1),item,index+25)
                  })

                  this.write_answer_sheet("RW",this.WP2.id,this.WP2_Filling,31)
                  this.write_answer_sheet("RW",this.WP3.id,this.WP3_Filling,32)
                
                  this.LP2_Blanks.forEach((item,index)=>{
                    this.write_answer_sheet("L",this.LP2.id+"-0"+(index+1),item,index+6)
                  })

                  this.LP5_Filling.forEach((item,index)=>{
                    this.write_answer_sheet("L",this.LP5.id+"-0"+(index+1),item,index+23)
                  })
                  // 分析结果
                  if (await this.analyze_paper(this.answers)==1){
                    this.$router.push('/testing-report?uid='+this.uid)
                  }else{
                    ElMessage({
                      message: '分析结果出错，请重试',
                      type: 'error',
                    })
                  }
            }catch{
                  this.loading=false
            }
            
          },
         
            async analyze_paper(answers){
              const headers={
                    'Content-type': 'application/json'
                }
              const data={
                  "answers":answers,
                  "uid":this.uid
                }
              try {
                    const res = await axios.post('/ai-testing/api/analyze_paper/', data, { headers });
                    console.log("analyze_paper res:", res.data.resCode);

                    if (res.data.resCode === 20000) {
                      console.log("返回：", 1);
                      return 1; // 正确返回值
                    } else {
                      console.log("返回：", 0);
                      return 0; // 返回值为 0
                    }
                  } catch (err) {
                    console.log(err);
                    return 0; // 在错误时返回 0
                  }
          },
        },

      }
  </script>
   
<style scoped>
div#app{
  display: flex;
  justify-content: center;
}
.container{
  display: flex;
  margin-left:auto;
  margin-right: auto;
  justify-content: center; /*水平居中*/
  align-items: center; /*垂直居中*/
  flex-direction: column;
  max-width:1200px;
}

.setup{
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content:center;
  background: #fff;
  width: 100%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
  }
  .title{
    display: flex;
    margin:30px 20px 10px 20px;
    font-size: 15pt;
    font-weight: 600;
    font-size: 14pt;
    color:#7d8084;
    }
    .memo{
    display: flex;
    margin:10px 20px 3px 40px;
    font-size: 10pt;
    font-weight: 400;
    color:#979a9f;
    }
    .memo:last-child{
    margin-bottom: 30px;
    }

.ready{
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content:left;
  background: #fff;
  width: 100%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
  }

.ready_rest_time{
  margin-top:20px;
  min-width: 200px;
}

.ready_rest_time_countdown{
  color:#409eff;
  font-size: 20pt;
  font-weight: 500;
  margin-left: 10px;
}
.ready_rest_time_startbutton{
  margin-top:20px;
  margin-bottom: 20px;
  margin-left: 40px;
}

.footer{
  position: fixed;
  bottom: 0;
  left:0;
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  
  justify-content:space-around;
  background: #fff;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}
.rest_question{
  display: flex;
  margin-top:30px;
  flex-direction: row;
}
.rest_question_num{
  display:block;
}
.rest_question_num_total{
  font-weight: 500; 
}
.rest_question_num_yet{
  font-weight: 550;
  color:#409eff;
}

.test_paper{
  display: flex;
  margin-bottom: 60px;
  flex-direction: column;
  justify-content:left;
  background: #fff;
  width: 100%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}

.test_paper_part_name{
  display: flex;
  margin:30px 20px 10px 20px;
  font-size: 15pt;
  font-weight: 600;
  color:#000; 
}
.test_paper_type_name{
  display: flex;
  margin:20px 40px;
  font-size: 13pt;
  font-weight: 500;
  color:#000;  
}
.test_paper_type_instruction{
  display: flex;
  text-align: left;
  margin: -10px 40px ;
  font-size: 12pt;
  font-weight: 450;
  color:#409eff;   
}

.listening_play_enable{
  display: flex;
  margin: 20px 40px 0 40px;
  background: #409eff;
  cursor:pointer;
}
.listening_play_disable{
  display: flex;
  margin: 20px 40px 0 40px;
  background: #a0cfff;
}

.test_paper_example{
  display: flex;
  text-align: left;
  margin: 20px 40px ;
  font-size: 12pt;
  font-weight: 450; 
}

.reading_part_1_group{
  display: flex;
  flex-direction: row;
  margin-bottom:30px;
}

.reading_part_1_group_text{
  /*text-align: left;
  font-weight: 600;
  font-size: 12pt;
  white-space: pre-wrap;
  border-right: 1px solid rgba(113, 113, 114, 0.15);*/
  display: flex;
  flex-direction: column;
  width: 40%;
  margin:0 40px;
  padding-right: 15px;
}
.reading_part_1_group_text_id{
  font-size: 10pt;
  color:#979a9f;
}
.reading_part_1_group_text_img{
  width: 45%;
  height:auto;
  margin-left: 20%;
}

.reading_part_1_group_stem_options{
  display: flex;
  flex-direction: column;
}
.reading_part_1_group_stem{
  display: block;
  text-align: left;
  font-size: 12pt;
  font-weight: 600;
  margin-bottom: 10px;
}
.reading_part_1_group_options{
  margin-bottom: 20px;
  text-align: left;
  cursor:pointer;
}
.reading_part_1_options{
  font-size: 12pt;
  font-weight: 450;
}
.reading_part_1_options_selected{
  font-size: 12pt;
  font-weight: 650;
  background:#409eff;
  color:#fff;
}

.reading_part_2_group_title{
  display: flex;
  justify-content: left;
  font-size:15pt;
  font-weight: 550;
  padding:0 40px;
  margin-bottom: 40px;
}
.reading_part_2_group{
  display: flex;
  flex-direction: row;
  margin-bottom:20px; 
}
.reading_part_2_group_text{
  margin:20px 40px;
  width:50%;
  padding-right:20px;
  border-right: 1px solid rgba(113, 113, 114, 0.15); 
}
.reading_part_2_group_text_item{
  text-align: left;
  margin-bottom: 20px;
}
.reading_part_2_group_text_item_name{
  font-size: 12pt;
  font-weight: 550;
  margin-bottom: 10px;
}
.reading_part_2_group_text_item_text{
  font-size: 12pt;
  font-weight: 450;
  margin-bottom: 10px;
}
.reading_part_2_group_stem_options{
  display: flex;
  flex-direction: column;
}
.reading_part_2_group_names{
  display:flex;
  flex-direction:row;
  justify-content: end;
}
.reading_part_2_group_names_row{
  margin-right:40px;
}
.reading_part_2_group_names_row_tag{
  display: inline-block;
  width:33.3%;
  font-weight:550;
}
.reading_part_2_stem{
  display: flex;
  flex-direction: row;
}
.reading_part_2_stem_group{
  display: flex;
  flex-direction: column;
}
.reading_part_2_group_stem_options_row{
  display:flex;
  flex-direction:row
}
.reading_part_2_group_stem_options_row_stem{
  width: 60%;
  text-align: left;
  margin:20px 20px 20px 0;
  font-weight: 500;
}
.reading_part_2_group_stem_options_row_option{
  text-align: center;
  width:40%;
  margin:20px  0; 
  cursor:pointer;
}
.reading_part_2_group_stem_options_row_option_tag{
  display:inline-block;
  width:30px;
  height:25px;
  margin-right: 35px;
  text-align: center;
  padding-top: 5px;
  background: rgba(113, 113, 114, 0.15);
  border: 1px solid rgba(113, 113, 114, 0.15);
  border-radius: 80px;
 }

 .reading_part_2_group_stem_options_row_option_tag_selected{
  display:inline-block;
  width:30px;
  height:25px;
  margin-right: 35px;
  text-align: center;
  padding-top: 5px;
  background: #409eff;
  border: 1px solid #409eff;
  color: #fff;
  border-radius: 80px;
 }

.reading_part_3_group{
  display: flex;
  flex-direction: row;
}
.reading_part_3_group_text_group{
  display: flex;
  flex-direction: column;
  width:50%;
  margin:20px;
  text-align: left;
}
.reading_part_3_group_text_group_title{
  font-size: 14pt;
  font-weight: 550;
  text-align: center;
  margin-bottom: 20px;  
}
.reading_part_3_group_text_group_text{
  margin: 0 20px;
  line-height: 22pt;
  white-space: pre-wrap;  
}
.reading_part_3_group_stem_options_group{
  display: flex;
  flex-direction: column; 
  text-align: left;
  margin:40px 40px;
}
.reading_part_3_group_stem_options{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.reading_part_3_group_stem{
  display: block;
  text-align: left;
  font-size: 12pt;
  font-weight: 600;
  margin-bottom: 10px; 
}
.reading_part_3_group_options{
  display: block;
  text-align: left;
  font-size: 12pt;
  margin-bottom: 10px; 
  cursor: pointer;
}
.reading_part_3_group_options_selected{
  display: block;
  text-align: left;
  font-size: 12pt;
  margin-bottom: 10px; 
  cursor: pointer;
  font-size: 12pt;
  font-weight: 650;
  background:#409eff;
  color:#fff;
}

.reading_part_4_group{
  display: flex;
  flex-direction: row;
}
.reading_part_4_group_text_group{
  display: flex;
  flex-direction: column;
  width:50%;
  margin:20px;
  text-align: left;
}
.reading_part_4_group_text_group_title{
  font-size: 14pt;
  font-weight: 550;
  text-align: center;
  margin-bottom: 20px;  
}
.reading_part_4_group_text_group_text{
  margin: 0 20px;
  line-height: 22pt;
  white-space: pre-wrap;  
}
.reading_part_4_group_stem_options_group{
  display: flex;
  flex-direction: column; 
  text-align: left;
  margin:40px 40px;
}
.reading_part_4_group_stem_options{
  display: flex;
  /*flex-direction: column;*/
  align-items: left;
  margin-bottom: 20px;
}
.reading_part_4_group_stem{
  display: block;
  text-align: left;
  font-size: 12pt;
  font-weight: 600;
  margin-bottom: 10px; 
}
.reading_part_4_group_options{
  display: block;
  text-align: left;
  font-size: 12pt;
  margin:0 10px 10px 10px; 
  cursor: pointer;
}
.reading_part_4_group_options_selected{
  display: block;
  text-align: left;
  font-size: 12pt;
  /*margin-bottom: 10px;*/ 
  cursor: pointer;
  font-size: 12pt;
  font-weight: 650;
  background:#409eff;
  color:#fff;
}

.writing_part_5_group{
  display: flex; 
  flex-direction: column;
}
.writing_part_5_group_text{
  margin: 0 40px;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left; 
}
.writing_part_5_group_blanks{
  display: flex;
  flex-direction: row;
  margin:  20px 40px;
}
.writing_part_6_group{
  display: flex; 
  flex-direction: column; 
}
.writing_part_6_group_text{
  display:block;
  margin: 0 40px;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left;  
}
.writing_part_6_group_stem{
  display:block;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left;  
}
.writing_part_6_group_require{
  display:block;
  margin: 20px;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left;  
}
.writing_part_6_group_notic{
  display:block;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left;  
  color:#409eff;
  font-weight: 600;
}
.writing_part_6_group_blanks{
  display: flex;
  justify-items: left;
  margin: 20px 40px;
}

.writing_part_7_group{
  display: flex; 
  flex-direction: column;  
}
.writing_part_7_group_imgs{
  display: flex;
  flex-direction: row;
  margin: 0 40px;
}
.writing_part_7_group_image{
  border: 1px solid #000; 
  margin:0 40px;
  width:150px;
  height:auto;
  flex:1;
}
.writing_part_7_group_text{
  display:block;
  margin: 0 20px;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left;  
}
.writing_part_7_group_stem{
  display:block;
  line-height: 22pt;
  margin: 0 20px;
  white-space: pre-wrap;  
  text-align: left;  
}
.writing_part_7_group_require{
  display:block;
  margin: 20px;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left;  
}
.writing_part_7_group_notic{
  display:block;
  line-height: 22pt;
  margin:20px 40px 0 40px;
  white-space: pre-wrap;  
  text-align: left;  
  color:#409eff;
  font-weight: 600;
}
.writing_part_7_group_blanks{
  display: flex;
  justify-items: left;
  margin: 20px 40px;
}

.listening_part_select_img_group{
  display: flex;
  flex-direction: column;
  margin-bottom:20px;
}
.listening_part_select_img_stem{
  display: flex;
  margin-left: 40px;
  font-size: 12pt;
  font-weight: 550;
  margin-bottom:10px;
}
.listening_part_select_img_stem_playbtn{
  margin-left:10px;
  color:#fff;
  cursor: pointer;
  font-weight: 600;
}
.listening_part_select_img_stem_playbtn:last-child{
  margin-left:5px;
  margin-right:10px;
}
.listening_part_select_img_options{
  display: flex;
  flex-direction: row;
  justify-content: space-around; /* Distribute space evenly */
  margin-left: 40px;
  margin-right: 40px;
}
.listening_part_select_img_quiz{
  display: flex;
  flex-direction:column;
  cursor: pointer;
}
.listening_part_select_img_quiz_pic{
  width: 150px;
  height:100px;
  margin-right: 10px;
  border: 1px solid #000;
}
.listening_part_select_img_quiz_pic_selected{
  border: 6px solid #409eff;
  width: 150px;
  height:100px;
  margin-right: 10px;
}
.listening_part_select_img_quiz_option{
  display: block;
}
.listening_part_select_img_quiz_option_selected{
  font-weight: 600;
  color:#409eff;
}


.listening_part_2_group_text_group{
  display: flex;
  flex-direction: column;
  margin: 20px 40px;
}

.listening_part_2_group_text_group_quizs{
  display: flex;
  flex-direction: row; 
  margin-bottom: 30px;
}
.listening_part_2_group_text_group_quizs_stem{
  margin-right: 30px;
}
.listening_part_2_group_text_group_quizs_blank{
 display: flex;
 justify-items:flex-end;
}

.listening_part_3_group_stem_options,.listening_part_4_group_stem_options {
  margin:20px 40px;
}
.listening_part_3_group_stem{
  font-weight:600;
  margin-bottom:20px;
  text-align: left;
}
.listening_part_3_group_options{
  display: block;
  text-align: left;
  font-size: 12pt;
  margin:20px 0;
  /*margin:0 20px 10px 10px; */
  cursor: pointer; 
}
.listening_part_3_group_options_selected{
  display: block;
  text-align: left;
  font-size: 12pt;
  /*margin:0 20px 10px 10px;*/
  margin:20px 0px; 
  cursor: pointer;
  font-size: 12pt;
  font-weight: 650;
  background:#409eff;
  color:#fff;
}

.listening_part_4_group_stem{
  font-weight:600;
  margin:20px 0;
  text-align: left;
  white-space: pre-wrap;  
}

.listening_part_4_group_options{
  display: block;
  text-align: left;
  font-size: 12pt;
  /*margin:20px 40px;*/
  margin:20px 0;
  cursor: pointer;  
}
.listening_part_4_group_options_selected{
  display: block;
  text-align: left;
  font-size: 12pt;
  /*margin:0 20px 10px 10px; */
  margin: 20 0px;
  cursor: pointer;
  font-size: 12pt;
  font-weight: 650;
  background:#409eff;
  color:#fff; 
}

.listening_part_5_group_stem{
  font-weight:600;
  margin:20px;
  text-align: left;
  white-space: pre-wrap;  
}

.listening_part5_group{
  display: flex; 
  flex-direction: row;   
  margin:40px 40px;
  justify-content: center;
}

.listening_part5_group_blanks{
  display: flex;
  flex-direction: column;
  margin:0 100px;
  padding-right:100px;
  border-right: 1px solid #00000025;
}
.listening_part5_group_blanks_text{
  display: block;
  margin:10px 0;
  text-align: right;
}
.listening_part5_group_options{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom:20px;
}
.listening_part5_group_options_item{
  display: block;
  margin:10px 0;
}

.speaking_dail{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.speaking_dailing{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:500px;
  height: auto;
  background: #21273c;
  display:flex;
  flex-direction: row;
  border: #000 solid 1px;
  border-radius: 12px;
}

.speaking_dailing_avatar_group{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
}

.speaking_dailing_interlocutor{
  display:flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}
.speaking_dailing_interlocutor_avatar{
  background-image: url( "../assets/rebot.png" );
  border-radius: 8px;
  border: 1px solid #cccccc85;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom:10px;
}

.speaking_dailing_interlocutor_name,.speaking_dailing_you_name,.speaking_dailing_partner_name{
  color:#fff;
  white-space: nowrap;
  margin:0 20px;
}


.speaking_dailing_you{
  display:flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.speaking_dailing_you_avatar{
  background:#409eff;
  border-radius: 8px;
  border: 1px solid #cccccc85;
  width: 60px;
  height: 60px;
  margin-bottom:10px;
 color:#fff;
 font-size: 20pt;
 font-weight: 550;
 align-content: center;
}

.speaking_dailing_partner{
  display:flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}


.speaking_dailing_partner_avatar{
  background:#4b4b4b;
  border-radius: 8px;
  border: 1px solid #cccccc85;
  width: 60px;
  height: 60px;
  margin-bottom:10px;
 color:#409eff;
 font-size: 20pt;
 font-weight: 550;
 align-content: center;
}

.speaking_dailing_img{
display: flex;
align-items: center;
justify-content: center;
  margin:20px;
}
.speaking_dailing_img img{
  width: 700px;
  height: auto;
}
  @keyframes flip {
    0% {
        transform: rotateY(0);
    }
    25% {
      transform: rotateY(90deg);
      opacity: 0.25;
    }
    50% {
      transform: rotateY(180deg);
      opacity: 0.5;
    }
    75% {
      transform: rotateY(270deg);
      opacity: 0.75;
    }
    100% {
        transform: rotateY(0);
    }
  }
    .flipping {
        animation: flip 2s ease-in-out forwards;
    }

    @keyframes jelly{
      0%,100%{
        transform: scale(1);
      }
      50%{
        transform:scale(1.1);
      }
  }
  .jelly{
    animation: jelly 2s ease-in-out forwards;
  }

  @keyframes breathe{  
    0%, 100% {  
      /* 起始和结束状态，透明度较低，颜色较暗 */  
      opacity: 0.5;  
      border: 3px solid rgba(0, 123, 255, 0.5); /* 蓝色，但透明度较低 */  
    }  
    50% {  
      /* 中间状态，透明度较高，颜色较亮 */  
      opacity: 1;  
      border: 3px solid rgba(0, 123, 255, 1); /* 完全的蓝色 */  
    }  
  }  
  .breathing_light {  
    animation: breathe 2s infinite ease-in-out; /* 2秒一个周期，无限循环，缓动效果 */  
  }


.spoken_answer_group{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin:40px 20px;
  position: relative;
}
.spoken_button{
  position: absolute;
  bottom: 0px;
}
.spoken_group_frame{
  display: flex;
  flex-direction: column;
  margin:0  50px 0 100px;
  padding-right:100px;
  border-right: 1px solid #00000025; 
}
.spoken_answer_group_talk{
  margin-bottom:20px;
}

@media screen and (max-width: 1024px) {
  .ready{
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content:left;
    background: #fff;
    width: 100%;
    border: 1px solid rgba(68,74,98,0.15);
    border-radius: 2px;
    box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
    }
  
  .ready_rest_time{
    margin-top:20px;
    margin-left:20px;
    min-width: 100px;
    text-align: left;
  }
  
  .ready_rest_time_countdown{
    color:#409eff;
    font-size: 20pt;
    font-weight: 500;
    margin-left: 10px;
  }
  .ready_rest_time_startbutton{
    margin-top:20px;
    margin-bottom: 20px;
    margin-left: 40px;
  }  

.footer{
  position: fixed;
  bottom: 0;
  left:0;
  width: 100%;
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  
  justify-content:space-around;
  background: #fff;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}
.rest_question{
  display: flex;
  margin-top:30px;
  margin-left:20px;
  flex-direction: row;
}
.rest_question_num{
  display:block;
}
.rest_question_num_total{
  font-weight: 500; 
}
.rest_question_num_yet{
  font-weight: 550;
  color:#409eff;
}

  .reading_part_1_group{
    display: flex;
    flex-direction: column;
    margin-bottom:20px;
  } 
  .reading_part_1_group_text{
    text-align: left;
    font-weight: 600;
    width: 80%;
    margin:0 0 20px 40px;
    font-size: 12pt;
    white-space: pre-wrap; /* 换行 */
    padding-right: 15px;
    border-right: 0px;
    }
  .reading_part_1_group_stem_options{
    display: flex;
    flex-direction: column;
    margin-left:40px;
  }
  .reading_part_1_group_stem{
    display: block;
    text-align: left;
    font-size: 12pt;
    font-weight: 600;
    width:80%;
    margin-bottom: 10px;
  }
  .reading_part_1_group_options{
    margin-bottom: 20px;
    text-align: left;
    cursor:pointer;
    width:80%;
  }
  .reading_part_1_options{
    font-size: 12pt;
    font-weight: 450;
  }
  .reading_part_1_options_selected{
    font-size: 12pt;
    font-weight: 650;
    background:#409eff;
    color:#fff;
  }

  .reading_part_2_group_title{
    display: flex;
    justify-content: left;
    font-size:15pt;
    font-weight: 550;
    padding:0 40px;
    margin-bottom: 40px;
  }
  .reading_part_2_group{
    display: flex;
    flex-direction: column;
    margin-bottom:20px; 
  }
  .reading_part_2_group_text{
    margin:20px 40px;
    width:80%;
    padding-right:20px;
    border-right: 0px ; 
  }
  .reading_part_2_group_text_item{
    text-align: left;
    margin-bottom: 20px;
  }
  .reading_part_2_group_text_item_name{
    font-size: 12pt;
    font-weight: 550;
    margin-bottom: 10px;
  }
  .reading_part_2_group_text_item_text{
    font-size: 12pt;
    font-weight: 450;
    margin-bottom: 10px;
  }
  .reading_part_2_group_stem_options{
    display: flex;
    flex-direction: column;
    width:80%;
    margin-left:40px;
  }
  .reading_part_2_group_names{
    display:flex;
    flex-direction:row;
  }
  .reading_part_2_group_names_row{
    margin-left:60%;
  }
  .reading_part_2_group_names_row_tag{
    display: inline-block;
    width:33.3%;
    font-size:10pt;
    font-weight:550;
  }
  .reading_part_2_stem{
    display: flex;
    flex-direction: row;
  }
  .reading_part_2_stem_group{
    display: flex;
    flex-direction: column;
  }
  .reading_part_2_group_stem_options_row{
    display:flex;
    flex-direction:row
  }
  .reading_part_2_group_stem_options_row_stem{
    width: 60%;
    text-align: left;
    margin:20px 20px 20px 0;
    font-weight: 500;
  }
  .reading_part_2_group_stem_options_row_option{
    text-align: center;
    width:40%;
    margin:20px  0; 
    cursor:pointer;
  }
  .reading_part_2_group_stem_options_row_option_tag{
    display:inline-block;
    font-size:10pt;
    width:20px;
    height:20px;
    margin-right: 10px;
    text-align: center;
    padding-top: 2px;
    background: rgba(113, 113, 114, 0.15);
    border: 1px solid rgba(113, 113, 114, 0.15);
    border-radius: 20px;
   }
  
   .reading_part_2_group_stem_options_row_option_tag_selected{
    display:inline-block;
    font-size: 10pt;
    width:20px;
    height:20px;
    margin-right: 10px;
    text-align: center;
    padding-top: 2px;
    background: #409eff;
    border: 1px solid #409eff;
    color: #fff;
    border-radius: 20px;
   }

  .reading_part_3_group{
    display: flex;
    flex-direction: column;
  }
  .reading_part_3_group_text_group{
    display: flex;
    flex-direction: column;
    width:80%;
    margin:20px;
    text-align: left;
  }
  .reading_part_3_group_text_group_title{
    font-size: 14pt;
    font-weight: 550;
    text-align: center;
    margin-bottom: 20px;  
  }
  .reading_part_3_group_text_group_text{
    margin: 0 20px;
    line-height: 22pt;
    white-space: pre-wrap;  
  }
  .reading_part_3_group_stem_options_group{
    display: flex;
    flex-direction: column; 
    text-align: left;
    margin:40px 40px;
  }
  .reading_part_3_group_stem_options{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width:80%;
  }
  .reading_part_3_group_stem{
    display: block;
    text-align: left;
    font-size: 12pt;
    font-weight: 600;
    margin-bottom: 10px; 
  }
  .reading_part_3_group_options{
    display: block;
    text-align: left;
    font-size: 12pt;
    margin-bottom: 10px; 
    cursor: pointer;
  }
  .reading_part_3_group_options_selected{
    display: block;
    text-align: left;
    font-size: 12pt;
    margin-bottom: 10px; 
    cursor: pointer;
    font-size: 12pt;
    font-weight: 650;
    background:#409eff;
    color:#fff;
  }

  .reading_part_4_group{
    display: flex;
    flex-direction: column;
  }
  .reading_part_4_group_text_group{
    display: flex;
    flex-direction: column;
    width:80%;
    margin:20px;
    text-align: left;
  }
  .reading_part_4_group_text_group_title{
    font-size: 14pt;
    font-weight: 550;
    text-align: center;
    margin-bottom: 20px;  
  }
  .reading_part_4_group_text_group_text{
    margin: 0 20px;
    line-height: 22pt;
    white-space: pre-wrap;  
  }
  .reading_part_4_group_stem_options_group{
    display: flex;
    flex-direction: column; 
    text-align: left;
    margin:40px 20px;
    width:80%;
  }
  .reading_part_4_group_stem_options{
    display: flex;
    /*flex-direction: column;*/
    align-items: left;
    margin-bottom: 20px;
  }
  .reading_part_4_group_stem{
    display: block;
    text-align: left;
    font-size: 12pt;
    font-weight: 600;
    margin-bottom: 10px; 
  }
  .reading_part_4_group_options{
    display: block;
    text-align: left;
    font-size: 12pt;
    margin:0 10px 10px 10px; 
    cursor: pointer;
  }
  .reading_part_4_group_options_selected{
    display: block;
    text-align: left;
    font-size: 12pt;
    /*margin-bottom: 10px;*/ 
    cursor: pointer;
    font-size: 12pt;
    font-weight: 650;
    background:#409eff;
    color:#fff;
  }

  .writing_part_7_group{
    display: flex; 
    flex-direction: column;  
  }
  .writing_part_7_group_imgs{
    display: flex;
    flex-direction: column;
    margin: 20px 20px;
  }
  .writing_part_7_group_image{
    border: 1px solid #000; 
    margin:20px 20px;
  }
  .writing_part_7_group_text{
    display:block;
    margin: 0 20px;
    line-height: 22pt;
    white-space: pre-wrap;  
    text-align: left;  
  }
  .writing_part_7_group_stem{
    display:block;
    line-height: 22pt;
    margin: 0 20px;
    white-space: pre-wrap;  
    text-align: left;  
  }
  .writing_part_7_group_require{
    display:block;
    margin: 20px;
    line-height: 22pt;
    white-space: pre-wrap;  
    text-align: left;  
  }
  .writing_part_7_group_notic{
    display:block;
    line-height: 22pt;
    margin:20px 40px 0 40px;
    white-space: pre-wrap;  
    text-align: left;  
    color:#409eff;
    font-weight: 600;
  }
  .writing_part_7_group_blanks{
    display: flex;
    justify-items: left;
    margin: 20px 20px 40px 20px;
  }

  .listening_play_enable{
    display: flex;
    margin: 20px 40px 0 40px;
    background: #409eff;
    cursor:pointer;
  }
  .listening_play_disable{
    display: flex;
    margin: 20px 40px 0 40px;
    background: #a0cfff;
  }
  .listening_part_select_img_group{
    display: flex;
    flex-direction: column;
    margin-bottom:20px;
  }
  .listening_part_select_img_stem{
    display: flex;
    margin-left: 10px;
    font-size: 12pt;
    font-weight: 550;
    text-align: left;
    margin-bottom:10px;
  }
  .listening_part_select_img_stem_playbtn{
    margin-left:10px;
    color:#fff;
    cursor: pointer;
    font-weight: 600;
  }
  .listening_part_select_img_stem_playbtn:last-child{
    margin-left:5px;
    margin-right:10px;
  }
  .listening_part_select_img_options{
    display: flex;
    flex-direction: column;
    justify-content: space-around; /* Distribute space evenly */
    margin-left: 40px;
    margin-right: 40px;
  }
  .listening_part_select_img_quiz{
    display: flex;
    flex-direction:column;
    cursor: pointer;
  }
  .listening_part_select_img_quiz_pic{
    width: 150px;
    height:100px;
    margin-right: 10px;
    border: 1px solid #000;
  }
  .listening_part_select_img_quiz_pic_selected{
    border: 6px solid #409eff;
    width: 150px;
    height:100px;
    margin-right: 10px;
  }
  .listening_part_select_img_quiz_option{
    display: block;
    text-align: left;
    margin-bottom:20px;
  }
  .listening_part_select_img_quiz_option_selected{
    font-weight: 600;
    color:#409eff;
    text-align: left;
  }
  
.listening_part_2_group_text_group{
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
}

.listening_part_2_group_text_group_quizs{
  display: flex;
  flex-direction: column; 
  margin-bottom: 30px;
}
.listening_part_2_group_text_group_quizs_stem{
  margin-right: 30px;
  text-align: left;
}
.listening_part_2_group_text_group_quizs_blank{
 display: flex;
 flex-direction: column;
 text-align:left;
 /*justify-items:flex-end;*/
}

  .listening_part_5_group_stem{
    font-weight:600;
    margin:20px;
    text-align: left;
    white-space: pre-wrap;  
  }
  
  .listening_part5_group{
    display: flex; 
    flex-direction: row;   
    margin:20px 10px 40px 10px;
  }
  .listening_part5_group_blanks{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin:0 10px;
    padding-right:10px;
    border-right: 0px solid #00000025;
  }
  .listening_part5_group_blanks_text{
    display: block;
    margin:10px 0;
    text-align: left;
  }
  .listening_part5_group_options{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom:20px;
    margin-left:10px;
  }
  .listening_part5_group_options_item{
    display: block;
    margin:10px 0;
  }

  .speaking_dail{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .speaking_dailing{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    height: 120px;
    background: #21273c;
    display:flex;
    flex-direction: row;
    border: #000 solid 1px;
    border-radius: 12px;
  }
  
  .speaking_dailing_avatar_group{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .speaking_dailing_interlocutor{
    display:flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }
  .speaking_dailing_interlocutor_avatar{
    background-image: url( "../assets/rebot.png" );
    border-radius: 8px;
    border: 1px solid #cccccc85;
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom:10px;
  }
  
  .speaking_dailing_interlocutor_name,.speaking_dailing_you_name,.speaking_dailing_partner_name{
    color:#fff;
    white-space: nowrap;
    margin:0 20px;
  }
  
  
  .speaking_dailing_you{
    display:flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }
  
  .speaking_dailing_you_avatar{
    background:#409eff;
    border-radius: 8px;
    border: 1px solid #cccccc85;
    width: 60px;
    height: 60px;
    margin-bottom:10px;
   color:#fff;
   font-size: 20pt;
   font-weight: 550;
   align-content: center;
  }
  
  .speaking_dailing_partner{
    display:flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }
  
  
  .speaking_dailing_partner_avatar{
    background:#4b4b4b;
    border-radius: 8px;
    border: 1px solid #cccccc85;
    width: 60px;
    height: 60px;
    margin-bottom:10px;
   color:#409eff;
   font-size: 20pt;
   font-weight: 550;
   align-content: center;
  }
  
  .speaking_dailing_img{
  display: flex;
  align-items: center;
  justify-content: center;
    margin:20px;
  }
  .speaking_dailing_img img{
    width: 100%;
    height: auto;
  }
    @keyframes flip {
      0% {
          transform: rotateY(0);
      }
      25% {
        transform: rotateY(90deg);
        opacity: 0.25;
      }
      50% {
        transform: rotateY(180deg);
        opacity: 0.5;
      }
      75% {
        transform: rotateY(270deg);
        opacity: 0.75;
      }
      100% {
          transform: rotateY(0);
      }
    }
      .flipping {
          animation: flip 2s ease-in-out forwards;
      }
  
      @keyframes jelly{
        0%,100%{
          transform: scale(1);
        }
        50%{
          transform:scale(1.1);
        }
    }
    .jelly{
      animation: jelly 2s ease-in-out forwards;
    }
  
    @keyframes breathe{  
      0%, 100% {  
        /* 起始和结束状态，透明度较低，颜色较暗 */  
        opacity: 0.5;  
        border: 3px solid rgba(0, 123, 255, 0.5); /* 蓝色，但透明度较低 */  
      }  
      50% {  
        /* 中间状态，透明度较高，颜色较亮 */  
        opacity: 1;  
        border: 3px solid rgba(0, 123, 255, 1); /* 完全的蓝色 */  
      }  
    }  
    .breathing_light {  
      animation: breathe 2s infinite ease-in-out; /* 2秒一个周期，无限循环，缓动效果 */  
    }
  
}
</style>
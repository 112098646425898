<template>
    <div class="container">

        <div class="score-frame">
           
            <div class="overall">
                <span class="overall-conclusion">{{report.conclusion}}</span>
                <span class="overall-conclusion-score">综合成绩: {{ report.avg }}</span>
                <span class="overall-memo">KET考试适合非英语母语的儿童和青少年，通常适合11至14岁之间的学生。通过KET考试可以证明学生掌握了基础的英语沟通技能，对于未来的学术发展、留学申请或职场竞争都具有积极意义。KET证书对于小升初的简历和面试来说是一块敲门砖，能够展示孩子的英语学习能力‌</span>
                <span class="overall-memo">KET考试满分150分，评分标准如下：</span>
                <span class="overall-memo">Grade A（卓越）‌：总分140~150分</span>
                <span class="overall-memo">Grade B（优秀）‌：总分133~139分</span>
                <span class="overall-memo">‌Grade C（合格）‌：总分120~132分</span>
            </div>

            <div class="part-frame">
                <div class="part-frame-score">
                    <span class="score-text" :style="{color:Color.reading}">{{report.reading}}</span>
                </div>
                <div class="part-frame-detail">
                    <div  class="part-frame-detail-top" :style="{background:BackgroundColor.reading}">Reading</div>
                    <div  class="part-frame-detail-group" v-for="(i,index) in scoreDistribution.Read" :key="index">
                        <span :style="{ marginTop: index === 'part1' ? '100px' : '0px' }" class="part-frame-detail-group-partname">{{index}}</span>
                        <table class="score-table">
                            <tr  class="score-table-tr"><span v-for="(serial,index) in i" :key="index" class="part-frame-detail-group-part-item">{{Object.keys(serial)[0]}}</span>
                            </tr>
                            <tr  class="score-table-tr"><span v-for="(ans,index) in i" :key="index"  class="part-frame-detail-group-part-item"> {{Object.values(ans)[0]}}</span>
                            </tr>
                        </table>
                    </div>
                    <div  class="part-frame-detail-bottom" :style="{border:Border.reading}">{{ report.readingLevel }}</div>
                </div>
            </div>

            <div class="part-frame">
                <div class="part-frame-score">
                    <span  :style="{color:Color.listening}">{{report.listening}}</span>
                </div>
                <div class="part-frame-detail">
                    <div  class="part-frame-detail-top" :style="{background:BackgroundColor.listening}">Listening</div>
                    <div  class="part-frame-detail-group" v-for="(i,index) in scoreDistribution.Listen" :key="index">
                        <span :style="{ marginTop: index === 'part1' ? '100px' : '0px' }" class="part-frame-detail-group-partname">{{index}}</span>
                        <table class="score-table">
                            <tr  class="score-table-tr"><span v-for="(serial,index) in i" :key="index" class="part-frame-detail-group-part-item">{{Object.keys(serial)[0]}}</span>
                            </tr>
                            <tr  class="score-table-tr"><span v-for="(ans,index) in i" :key="index"  class="part-frame-detail-group-part-item"> {{Object.values(ans)[0]}}</span>
                            </tr>
                        </table>
                    </div>
                    <div  class="part-frame-detail-bottom" :style="{border:Border.listening}">{{ report.listeningLevel }}</div>
                    
                </div>
            </div>

            <div class="part-frame">
                <div class="part-frame-score">
                    <span class="score-text"   :style="{color:Color.writing}">{{report.writing}}</span>
                </div>
                <div class="part-frame-detail">
                    <div  class="part-frame-detail-top"  :style="{background:BackgroundColor.writing}">Writing</div>
                    <div  class="part-frame-detail-group" >
                        <span style="margin-top:100px" class="part-frame-detail-group-partname">part 6</span>
                        <table class="score-table">
                            <tr  class="score-table-tr">
                                <span class="part-frame-detail-group-part-item">内容</span>
                                <span class="part-frame-detail-group-part-item">条理</span>
                                <span class="part-frame-detail-group-part-item">语言</span>
                            </tr>
                            <tr  class="score-table-tr">
                                <span  class="part-frame-detail-group-part-item" style="margin-right: 30px">{{part6.content}}</span>
                                <span  class="part-frame-detail-group-part-item" style="margin-right: 30px">{{part6.orderliness}}</span>
                                <span  class="part-frame-detail-group-part-item">{{part6.language}}</span>
                            </tr>
                        </table>

                        <span style="margin-top:100px" class="part-frame-detail-group-partname">part 7</span>
                        <table class="score-table">
                            <tr  class="score-table-tr">
                                <span class="part-frame-detail-group-part-item">内容</span>
                                <span class="part-frame-detail-group-part-item">条理</span>
                                <span class="part-frame-detail-group-part-item">语言</span>
                            </tr>
                            <tr  class="score-table-tr">
                                <span  class="part-frame-detail-group-part-item" style="margin-right: 30px">{{part7.content}}</span>
                                <span  class="part-frame-detail-group-part-item"  style="margin-right: 30px">{{part7.orderliness}}</span>
                                <span  class="part-frame-detail-group-part-item" >{{part7.language}}</span>
                            </tr>
                        </table>
                    </div>
                        <div  class="part-frame-detail-bottom" :style="{border:Border.writing}">{{ report.writingLevel }}</div>
                    
                </div>
            </div>

            <div class="part-frame">
                <div class="part-frame-score">
                    <span class="score-text"   :style="{color:Color.speaking}">- -</span>
                </div>
                <div class="part-frame-detail">
                    <div  class="part-frame-detail-top"  :style="{background:BackgroundColor.speaking}">Speaking</div>
                    <div  class="part-frame-detail-group">
                        <span class="part-frame-detail-group-partname" style="margin-top:100px">暂不计算分数</span>
                    </div>
                   
                </div>
            </div>
        </div>
        <div style="display:flex;flex-direction:column;margin-top:50px">
            <span>扫描二维码，关注公众号，了解更多KET考试相关咨询。</span>
            <img style="margin:20px 0 0 40%; width:200px; height:200px;object-fit:cover" src="https://sam-test.oss-cn-beijing.aliyuncs.com/assets/qrcode_for_gh_d6dc12ce9fdb_258.jpg" />
        </div>
    </div>   
</template>
   
  <script>
   import axios from 'axios'
    export default {
        name: 'TestingReport',
        components:{
           
        },
        data(){
            return{
                BackgroundColor:{
                    "reading":"linear-gradient(to right,#2bd9a4,#1dcac8)",
                    "writing":"linear-gradient(to right,#bd7dd4,#9081de)",
                    "listening":"linear-gradient(to right,#fc8763,#ee6b67)",
                    "speaking":"linear-gradient(to right,#27c5cd,#3ba8e3)"
                },
                Color:{
                    "reading":"#1dcac8",
                    "writing":"#bd7dd4",
                    "listening":"#ee6b67",
                    "speaking":"#3ba8e3"
                },
                Border:{
                    "reading":"2px solid #1dcac8",
                    "writing":"2px solid #bd7dd4",
                    "listening":"2px solid #ee6b67",
                    "speaking":"2px solid #3ba8e3"
                },
                
               report:{
                "title":"Key English Test",
                "conclusion":"",
               
                "reading": 0,
                "writing":0,
                "listening": 0,
                "speaking":0,
                "avg":0,
                "readingLevel":"Brillent",
                "listeningLevel":"Brillent",
                "writingLevel":"Brillent",
                "speakingLevel":"None"
               },
               scoreDistribution:{"listen":[{"-":"-"}],"read":[{"-":"-"}]},
               part6:{"content":0,"orderliness":0,"language":0},
               part7:{"content":0,"orderliness":0,"language":0},
               
            }
        },
       
        mounted(){
            this.get_report(this.$route.query.uid)
    },
   onLoad(){
            
        }, 
    methods:{
        scoreConversion(s,t){
            console.log("s=",s,"t=",t)
            switch(t){
                case "r":
                if (s<=13){
                        return 100
                    }else if(s==14){
                        return 102
                    }else if(s==15){
                        return 105
                    }else if(s==16){
                        return 108
                    }else if(s==17){
                        return 111
                    }else if(s==18){
                        return 114
                    }else if(s==19){
                        return 117
                    }else if(s==20){
                        return 120
                    }else if(s==21){
                        return 122
                    }else if(s==22){
                        return 125
                    }else if(s==23){
                        return 127
                    }else if(s==24){
                        return 130
                    }else if(s==25){
                        return 132
                    }else if(s==26){
                        return 135
                    }else if(s==27){
                        return 137
                    }else if(s==28){
                        return 140
                    }else if(s==29){
                        return 145
                    }else if(s==30){
                        return 150
                    }
                    break;
                case "w":
                if (s<=12){
                        return 102
                    }else if(s==13){
                        return 105
                    }else if(s==14){
                        return 108
                    }else if(s==15){
                        return 111
                    }else if(s==16){
                        return 114
                    }else if(s==17){
                        return 117
                    }else if(s==18){
                        return 120
                    }else if(s==19){
                        return 123
                    }else if(s==20){
                        return 125
                    }else if(s==21){
                        return 128
                    }else if(s==22){
                        return 130
                    }else if(s==23){
                        return 133
                    }else if(s==24){
                        return 135
                    }else if(s==25){
                        return 138
                    }else if(s==26){
                        return 140
                    }else if(s==27){
                        return 143
                    }else if(s==28){
                        return 145
                    }else if(s==29){
                        return 148
                    }else if(s==30){
                        return 150
                    } 
                    break;
                case "l":
                if (s<=8){
                        return 89
                    }else if(s==9){
                        return 92
                    }else if(s==10){
                        return 96
                    }else if(s==11){
                        return 100
                    }else if(s==12){
                        return 103
                    }else if(s==13){
                        return 106
                    }else if(s==14){
                        return 110
                    }else if(s==15){
                        return 113
                    }else if(s==16){
                        return 116
                    }else if(s==17){
                        return 120
                    }else if(s==18){
                        return 123
                    }else if(s==19){
                        return 126
                    }else if(s==20){
                        return 130
                    }else if(s==21){
                        return 133
                    }else if(s==22){
                        return 136
                    }else if(s==23){
                        return 140
                    }else if(s==24){
                        return 145
                    }else if(s==25){
                        return 150
                    }
                    break;
                case "s":
                if (s<=18){
                        return 100
                    }else if(s==19){
                        return 102
                    }else if(s==20){
                        return 104
                    }else if(s==21){
                        return 107
                    }else if(s==22){
                        return 109
                    }else if(s==23){
                        return 111
                    }else if(s==24){
                        return 113
                    }else if(s==25){
                        return 116
                    }else if(s==26){
                        return 118
                    }else if(s==27){
                        return 120
                    }else if(s==28){
                        return 121
                    }else if(s==29){
                        return 123
                    }else if(s==30){
                        return 124
                    }else if(s==31){
                        return 126
                    }else if(s==32){
                        return 127
                    }else if(s==33){
                        return 129
                    }else if(s==34){
                        return 130
                    }else if(s==35){
                        return 131
                    }else if(s==36){
                        return 133
                    }else if(s==37){
                        return 134
                    }else if(s==38){
                        return 136
                    }else if(s==39){
                        return 137
                    }else if(s==40){
                        return 139
                    }else if(s==41){
                        return 140
                    }else if(s==42){
                        return 143
                    }else if(s==43){
                        return 145
                    }else if(s==44){
                        return 148
                    }else if(s==45){
                        return 150
                    }
                    break;
            }
            
        },
        async get_report(uid){
              const headers={
                    'Content-type': 'application/json'
                }
              const data={
                  "uid":uid
                }
                await axios.post('/ai-testing/api/get_report/',data,{headers})
                .then((res)=>{
                    this.report.reading=this.scoreConversion(res.data.reading,"r")
                    
                    this.report.writing=this.scoreConversion(res.data.writing,"w")
                   
                    this.report.listening=this.scoreConversion(res.data.listening,"l")
                   
                    this.scoreDistribution=res.data.scoreDistribution
                    this.part6=res.data.scoreDistribution.Write.part6
                    this.part7=res.data.scoreDistribution.Write.part7

                    console.log("sD",this.scoreDistribution)
                    
                    console.log("res:",res.data)
                    console.log("report:",this.report)
                    this.report.avg=Math.round((this.report.reading+this.report.writing+this.report.listening)/3)
                    
                    if (this.report.reading>=140){
                        this.report.readingLevel="Brilliant！"
                    }else if(this.report.reading>=133 && this.report.reading<140){
                        this.report.readingLevel="Excellent！"
                    }else if(this.report.reading>=120 && this.report.reading<=132){
                        this.report.readingLevel="Good!"
                    }else{
                        this.report.readingLevel="Fail！"
                    }

                    if (this.report.listening>=140){
                        this.report.listeningLevel="Brilliant！"
                    }else if(this.report.listening>=133 && this.report.listening<140){
                        this.report.listeningLevel="Excellent！"
                    }else if(this.report.listening>=120 && this.report.listening<=132){
                        this.report.listeningLevel="Good!"
                    }else{
                        this.report.listeningLevel="Fail！"
                    }

                    if (this.report.writing>=140){
                        this.report.writingLevel="Brilliant！"
                    }else if(this.report.writing>=133 && this.report.writing<140){
                        this.report.writingLevel="Excellent！"
                    }else if(this.report.writing>=120 && this.report.writing<=132){
                        this.report.writingLevel="Good!"
                    }else{
                        this.report.writingLevel="Fail！"
                    }
                    
                    if (this.report.avg>=140){
                        this.report.conclusion="Brilliant！"
                    }else if(this.report.avg>=133 && this.report.avg<140){
                        this.report.conclusion="Excellent！"
                    }else if(this.report.avg>=120 && this.report.avg<=132){
                        this.report.conclusion="Good!"
                    }else{
                        this.report.conclusion="Fail！"
                    }

                    return 0
                  }).catch((err)=>{
                    
                    console.log(err)
                  })
          } 
    }
  }
  </script>
   
   <style lang="scss" scoped>
   .container{
     display: flex;
     margin-left:auto;
     margin-right: auto;
     /*justify-content: center; /*水平居中*/
     /*align-items: center; /*垂直居中*/
     flex-direction: column;
     max-width:1200px;
   }

   .score-frame{
        display: flex;
        flex-direction: row;
   }     
   .overall{
        display: flex;
        background: #f3f4f6;
        flex-direction: column;
        flex:1;
        margin-right:20px;
   }
   .overall-conclusion{
        margin:30px 20px 10px 10px;
        font-size: 45px;
        font-weight: 600;
        padding-left:10px;
        text-align: left;
   }
   .overall-conclusion-score{
        margin-bottom: 30px;
        text-align: left;
        font-size: 15px;
        font-weight: 550;
        padding-left:50px;
   }
   .overall-memo{
    margin:10px 20px;
    text-align: left; 
    color:#888;
    font-size:10pt;
   }
   .overall-memo:last-child{
    margin-bottom: 30px;
   }
   .part-frame{
        display: flex;
        background: #f3f4f6;
        flex-direction: column;
        flex:1;
        margin-right:20px;
   }
   .part-frame-score{
    z-index: 2;
    position: absolute;
    margin:80px 70px;
    border: 1px solid #fff;
    width:80px;
    height:80px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 3px 3px 5px rgba(0,0,0,.2);
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 30px;
    font-weight: 600;
   }
   
   .part-frame-detail{
    z-index: 1;
    position: relative;
   }
   .part-frame-detail-top{
        font-size:20px;
        color:#fff;
        font-weight: 500;
        padding:40px;
   }
   .part-frame-detail-group{
    display: flex;
    flex-direction: column;
   }
   
   .part-frame-detail-bottom{
    position: relative;
    top:0%;
    padding:15px 60px;
    font-weight: 550;
    margin:20px;
    border-radius: 6px;
   }
   .part-frame-detail-group-partname{
        font-weight: 600;
        font-size:13pt;
   }
   .part-frame-detail-group-part-item{
        padding:5px;
   }
   .score-table{
        margin:20px 0;
        background: #9a9a9a25;
   }
   

@media screen and (max-width: 1024px) {
    .score-frame{
        display: flex;
        flex-direction: column;
        margin: 10px;
   }     
   .overall{
        display: flex;
        background: #f3f4f6;
        flex-direction: column;
        flex:1;
        margin-right:20px;
   }
   .overall-conclusion{
        margin:30px 20px 10px 10px;
        font-size: 45px;
        font-weight: 600;
        padding-left:10px;
        text-align: left;
   }
   .overall-conclusion-score{
        margin-bottom: 30px;
        text-align: left;
        font-size: 15px;
        font-weight: 550;
        padding-left:50px;
   }
   .overall-memo{
    margin:10px 20px;
    text-align: left; 
    color:#888;
    font-size:10pt;
   }
   .overall-memo:last-child{
    margin-bottom: 30px;
   }
   .part-frame{
        display: flex;
        background: #f3f4f6;
        flex-direction: column;
        flex:1;
        margin-bottom:20px;
   }
   .part-frame-score{
    z-index: 2;
    position: absolute;
    margin:80px 120px;
    border: 1px solid #fff;
    width:80px;
    height:80px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 3px 3px 5px rgba(0,0,0,.2);
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 30px;
    font-weight: 600;
   }
   
   .part-frame-detail{
    z-index: 1;
    position: relative;
    margin-bottom: 40px;
   }
   .part-frame-detail-top{
        font-size:20px;
        color:#fff;
        font-weight: 500;
        padding:40px;
   }
   .part-frame-detail-group{
    display: flex;
    flex-direction: column;
    margin-bottom:20px;
   }

   .part-frame-detail-bottom{
    padding:15px 60px;
    font-weight: 550;
    margin:20px 25%;
    border-radius: 6px;
    position: relative;
    top:60%;
   }
   .part-frame-detail-group-partname{
        font-weight: 600;
        font-size:13pt;
   }
   .part-frame-detail-group-part-item{
    
   } 
}


   
  
   </style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import OSS from 'ali-oss'
import ElIcon from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import MarkdownIt from 'markdown-it'
import VueMeta from 'vue-meta'
const md=new MarkdownIt()

const store = new OSS({
  region: 'oss-cn-beijing',
  accessKeyId: 'LTAI5t9TwDBMSBNR156JRb1g',
  accessKeySecret: 'r7ScfSNGPzdgeFIg7qo3tKnr9Xrv1T',
  bucket: 'tk-other-p0322'
  // region: 'oss-cn-beijing',
  // accessKeyId: '8mNrUbCs7IRk38dB',
  // accessKeySecret: 'L56xviBIKxK9EsCaVJNUCxbrUxFTOJ',
  // bucket: 'rtygtyhgh'
});

function extract_stream_response_string(contextStrings){
  console.log("contextStrings: ",contextStrings)
  if (typeof contextStrings === 'string') {  
    contextStrings = contextStrings.split('\n');  
  }  

  // 存储所有提取的 content 文本  
  const contents = [];  

  // 遍历每个字符串  
  contextStrings.forEach(str => {  
    // 去除前缀（如果有的话）  
    const cleanedStr = str.match(/data:(.*)/);  
    console.log("Str:",cleanedStr)
    if(cleanedStr!==null){
      try{
        const obj = JSON.parse(cleanedStr[1]);  
        // 检查对象是否包含 message 属性，且 message 属性包含 content 属性  
        //if (obj  && obj.message && obj.message.content) {  
          // 将 content 属性的值添加到结果数组中 
          console.log("取出的值：",obj)
         
          contents.push(obj.output.choices[0].message.content!="undefined"?obj.output.choices[0].message.content:'');
        //}
      }catch(e){
        console.error('解析错误:', e, '在字符串:', str);   
      }
    }
   });  

  // 返回所有提取的 content 文本  
  return contents;  
}

function extract_response_string(contextStrings){
  //console.log("contextStrings: ",contextStrings)
  if (typeof contextStrings === 'string') {  
    contextStrings = contextStrings.split('\\n');  
  }  
  console.log("contextStrings: ",contextStrings)
  // 存储所有提取的 content 文本  
  const contents = [];  
  //let i=0
  // 遍历每个字符串  
  contextStrings.forEach(str => {  
    // 去除前缀（如果有的话）  
    
    //console.log('Line<',i,'>:',str)
    const cleanedStr = str.match(/data:(.*)/);  
   
    if(cleanedStr!==null){
      console.log("Str:",cleanedStr[1].replace(/\\/g,""))
      try{
          const obj=JSON.parse(cleanedStr[1].replace(/\\/g,""))
          console.log("取出的值：",obj.output.choices[0].message,"类型是：",typeof obj)
          
          contents.push(obj.output.choices[0].message.content!="undefined"?obj.output.choices[0].message.content:'');
        //}
      }catch(e){
        console.error('解析错误:', e, '在字符串:',cleanedStr[1].replace(/\\/g,""));   
      }
    }
   });  

  // 返回所有提取的 content 文本  
  return contents;  
}

const app=createApp(App).use(ElementPlus).use(router).use(ElIcon)
app.use(VueMeta)
for(const [key,component] of Object.entries(ElementPlusIconsVue)){
  app.component(key,component)
}

app.config.globalProperties.$OSS=store
app.config.globalProperties.$MD=md
app.config.globalProperties.$PAT="Bearer pat_6T4ijAqWZACppWJ37unHwnId6f2yrki52bJom5dTMxlg11LMmk8nPybCXvCHKiiU"
app.config.globalProperties.$extract_stream_response_string=extract_stream_response_string
app.config.globalProperties.$extract_response_string=extract_response_string
app.mount('#app')